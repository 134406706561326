import React, { useContext, useMemo, useState } from 'react';
import Button, { ButtonColors } from '../../../common/Button';
import {
	ToastContext,
	ToastTypes,
} from '../../../common/contexts/toast.context';
import { Checkbox } from '../../../common/form/Checkbox';
import { Input } from '../../../common/form/Input';
import { Phone } from '../../../common/form/Phone';
import { Select } from '../../../common/form/Select';
import { useForm } from '../../../common/hooks/useForm';
import Modal, { ModalSizes } from '../../../common/Modal';
import { OpenClosedStates } from '../../../common/OpenClosedStates';
import { STATES } from '../../../constants';
import * as userService from '../../../services/user.service';
import ConfirmModal from '../../../common/ConfirmModal';
import Dropdown from '../../../common/form/Dropdown';
import { Toggle } from '../../../common/form/Toggle';
import AddContactEmailModal from '../../account-settings/AddContactEmailModal';
import NotificationIcon from '../../../common/notifications/NotificationIcon';

type LocationForm = {
	id?: number;
	address: string;
	city: string;
	state: string;
	zip: string;
	path: string;
	website?: string;
	phone: string;
	email: string;
	active: boolean;
	contactFormMethodText: boolean;
	contactFormMethodEmail: boolean;
	contactFormTextNumber?: string;
	contactFormEmails?: string[];
	shedSuiteDealerId?: number;
	shedAppDealerId?: number;
};

const validation = {
	phone: {
		pattern: {
			value: new RegExp(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/),
			message: 'Provide a valid phone number',
		},
		required: {
			message: 'Phone is required.',
		},
	},
	email: {
		required: {
			message: 'Email is required.',
		},
	},
	address: {
		required: {
			message: 'Address is required.',
		},
	},
	city: {
		required: {
			message: 'City is required.',
		},
	},
	state: {
		required: {
			message: 'State is required.',
		},
	},
	zip: {
		required: {
			message: 'Zip code is required.',
		},
	},
	path: {
		required: {
			message: 'URL part is required.',
		},
		minLength: {
			value: 4,
			message: 'URL part must be at least 4 characters.',
		},
		maxLength: {
			value: 512,
			message: 'URL part must be at most 512 characters.',
		},
		pattern: {
			value: /^[a-z0-9-_]+$/,
			message:
				'Path can only contain lowercase characters, numbers, hyphens & underscores.',
		},
		contactFormMethodText: {
			function: {
				value: (val: Partial<LocationForm>) => {
					if (val.contactFormMethodText && !val.contactFormTextNumber) {
						return 'Please provide a phone number that can receive text messages.';
					}
					return false;
				},
			},
		},
	},
	contactFormTextNumber: {
		pattern: {
			value: new RegExp(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/),
			message: 'Provide a valid phone number',
		},
	},
	shedSuiteDealerId: {
		pattern: {
			value: /^\d+$/,
			message: 'Provide a valid Shed Suite Dealer Id',
		},
	},
	shedAppDealerId: {
		pattern: {
			value: /^\d+$/,
			message: 'Provide a valid Shed App Dealer Id',
		},
	},
};

type Props = {
	location: any;
	state: OpenClosedStates;
	setState: React.Dispatch<React.SetStateAction<OpenClosedStates>>;
	accountId: number;
	hasApiAccess: boolean;
	path?: string;
	updateLocations: () => void;
	hasSms?: boolean;
	clearLocation?: () => void;
	externalProvidersInfo?: {
		apiKey: string;
		customerShedSuiteId: number;
		customerShedAppUrl: string;
	};
};

const AccountLocationModal: React.FC<Props> = ({
	state,
	setState,
	location = {},
	accountId,
	hasApiAccess,
	path,
	updateLocations,
	hasSms,
	clearLocation,
	externalProvidersInfo,
}) => {
	const shedSuiteApiKey = externalProvidersInfo?.apiKey;
	const { createToast } = useContext(ToastContext);
	const [confirmDeleteLocationModal, setConfirmDeleteLocationModal] =
		useState<OpenClosedStates>(OpenClosedStates.Closed);
	const [addContactEmailModal, setAddContactEmailModal] =
		useState<OpenClosedStates>(OpenClosedStates.Closed);
	const [focusedEmail, setFocusedEmail] = useState<string | null>(null);

	const {
		value,
		registerSubmit,
		isDirty,
		errors,
		onChange,
		isSubmitting,
		patchValue,
	} = useForm<LocationForm, 'SUCCESS'>(location, validation);

	const [confirmModalState, setConfirmModalState] = useState<OpenClosedStates>(
		OpenClosedStates.Closed
	);

	const smsConfirm = useMemo(() => {
		if (hasSms && !location.active && value.active) {
			return true;
		} else return false;
	}, [hasSms, location.active, value.active]);

	const isNew = useMemo(() => {
		return !location.id;
	}, [location.id]);

	const onSuccess = (res: any) => {
		setState(OpenClosedStates.Closed);
		if (clearLocation) {
			clearLocation();
		}
		createToast({
			title: 'Success!',
			description: <>Successfully saved location!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
		updateLocations();
	};

	const onClose = () => {
		setState(OpenClosedStates.Closed);
		if (clearLocation) {
			clearLocation();
		}
	};

	const onFail = (e: any) => {
		let errorMsg = e?.response?.data?.message;
		createToast({
			title: 'Error!',
			description: errorMsg ? errorMsg : 'Failed to save location!',
			type: ToastTypes.Fail,
			duration: 5000,
		});
	};

	const onSubmit = async (val: Partial<LocationForm>) => {
		if (!accountId) {
			return;
		}
		return userService.saveLocation({
			accountId: accountId,
			location: val,
		});
	};

	const onDelete = async () => {
		if (!value.id || !accountId) {
			return;
		}
		try {
			await userService.deleteLocation({
				accountId: accountId,
				locationId: value.id,
			});
			setConfirmDeleteLocationModal(OpenClosedStates.Closed);
			setState(OpenClosedStates.Closed);
			if (clearLocation) {
				clearLocation();
			}
			createToast({
				title: 'Success!',
				description: <>Successfully deleted location!</>,
				type: ToastTypes.Success,
				duration: 5000,
			});
			updateLocations();
		} catch (err) {
			console.log(err);
			createToast({
				title: 'Error!',
				description: <>Failed to delete location!</>,
				type: ToastTypes.Fail,
				duration: 5000,
			});
		}
	};

	const handleConfirmBeforeSubmit = () => {
		setConfirmModalState(OpenClosedStates.Open);
		return;
	};

	const handleBlur = (emailValue: string) => {
		let updatedEmails = [...(value.contactFormEmails || [])];

		if (focusedEmail && updatedEmails.includes(focusedEmail)) {
			updatedEmails = updatedEmails.filter((email) => email !== focusedEmail);
		}

		if (!updatedEmails.includes(emailValue)) {
			updatedEmails.push(emailValue);
		}

		patchValue({ contactFormEmails: updatedEmails });
		setFocusedEmail(null);
	};

	return (
		<>
			<form onSubmit={registerSubmit(onSubmit, { onSuccess, onFail })}>
				<Modal state={state} size={ModalSizes.xl} minHeight={625}>
					<>
						<div>
							<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-500">
								<svg
									className="h-6 w-6 text-gray-600 dark:text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
									/>
								</svg>
							</div>
							<div className="mt-3 text-center sm:mt-5">
								<h3
									className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100"
									id="modal-headline"
								>
									{isNew ? 'Add' : 'Edit'} location
								</h3>
								<div className="mt-2">
									<p className="text-sm leading-5 text-gray-500 dark:text-gray-400">
										Enter location information
									</p>
								</div>
								<div className="text-left pt-6 grid grid-cols-6 gap-2">
									<Phone
										name="phone"
										value={value.phone}
										errors={errors.fieldErrors?.phone}
										onChange={onChange('phone')}
										className="col-span-3"
										label="Phone *"
									/>
									<Input
										label="Email *"
										type="email"
										value={value.email}
										onChange={onChange('email')}
										className="col-span-3"
										name="email"
										errors={errors.fieldErrors?.email}
										onBlur={handleBlur}
									/>
									<Input
										label="Website"
										value={value.website}
										onChange={onChange('website')}
										name="website"
										className="col-span-6"
										errors={errors.fieldErrors?.website}
									/>
									<Input
										label="Address *"
										value={value.address}
										onChange={onChange('address')}
										name="address"
										className="col-span-6"
										errors={errors.fieldErrors?.address}
									/>
									<Input
										label="City *"
										value={value.city}
										onChange={onChange('city')}
										name="city"
										className="col-span-3"
										errors={errors.fieldErrors?.city}
									/>
									<Select
										label="State *"
										value={value.state}
										onChange={onChange('state')}
										name="state"
										className="col-span-2"
										errors={errors.fieldErrors?.state}
										options={STATES}
										limit={1}
									/>
									<Input
										label="Zip *"
										value={value.zip}
										onChange={onChange('zip')}
										name="zip"
										className="col-span-1"
										errors={errors.fieldErrors?.zip}
									/>
									{shedSuiteApiKey &&
										externalProvidersInfo?.customerShedSuiteId &&
										hasApiAccess && (
											<Input
												label="Shed Suite Dealer Id"
												value={value.shedSuiteDealerId}
												onChange={onChange('shedSuiteDealerId')}
												name="shedSuiteDealerId"
												className="col-span-6"
												errors={errors.fieldErrors?.shedSuiteDealerId}
											/>
										)}
									{externalProvidersInfo?.customerShedAppUrl &&
										hasApiAccess && (
											<Input
												label="Shed App Dealer Id"
												value={value.shedAppDealerId}
												onChange={onChange('shedAppDealerId')}
												name="shedAppDealerId"
												className="col-span-6"
												errors={errors.fieldErrors?.shedAppDealerId}
											/>
										)}
									<div className="col-span-6 flex flex-col">
										<span>Contact form preferences</span>

										<div className="col-span-6 grid grid-cols-6">
											<Toggle
												label={'Text'}
												value={value.contactFormMethodText || false}
												onChange={onChange('contactFormMethodText')}
												errors={errors.fieldErrors?.contactFormMethodText}
												className="col-span-2"
												disabled
												tooltip={
													<span>
														Super admin cannot change user subscription features
													</span>
												}
											/>
											<Phone
												className="col-span-3"
												name="contactFormTextNumber"
												label="Text message"
												value={value.contactFormTextNumber}
												onChange={onChange('contactFormTextNumber')}
												errors={errors.fieldErrors?.contactFormTextNumber}
												disabled={!location?.contactFormMethodText}
											/>
										</div>

										<div className="col-span-6 grid grid-cols-6">
											<Toggle
												label={'Email'}
												value={value.contactFormMethodEmail || false}
												onChange={onChange('contactFormMethodEmail')}
												errors={errors.fieldErrors?.contactFormMethodEmail}
												className="col-span-2"
												tooltip={
													!value.contactFormMethodText ? (
														<span>
															You must have one contact form method selected.
														</span>
													) : undefined
												}
												disabled={!value.contactFormMethodText}
											/>
											<Dropdown
												valueProp="id"
												textProp="name"
												className="col-span-3"
												name="contactFormEmails"
												label="Contact form emails"
												options={[
													...(value.contactFormEmails || []).map(
														(email: string) => {
															return { name: email, id: email };
														}
													),
													{ name: 'Add new', id: '$add' },
												]}
												onAdd={() =>
													setAddContactEmailModal(OpenClosedStates.Open)
												}
												patchValue={patchValue}
											/>
										</div>
									</div>
								</div>

								<Input
									name="path"
									className="col-span-6"
									label="URL part *"
									value={value.path}
									onChange={onChange('path')}
									errors={errors.fieldErrors?.path}
									hint={
										<>
											Your location page will be{' '}
											{value.path && (
												<a
													href={`${process.env.REACT_APP_CUSTOMER_URL}/dealers/${path}/locations/${value.path}`}
													target="_blank"
													rel="noreferrer"
													className="text-primary-500 hover:text-primary-600"
												>
													{process.env.REACT_APP_CUSTOMER_URL}/dealers/
													{path}/locations/{value.path}
												</a>
											)}
											.
										</>
									}
									onBlur={(val) =>
										onChange('path')(val?.toLowerCase()?.replace(/ /g, '-'))
									}
								/>
								<Checkbox
									name="active"
									className="col-span-6"
									label="Active"
									value={value.active}
									onChange={onChange('active')}
									errors={errors?.fieldErrors?.active}
								/>
							</div>
						</div>
						<div
							className={[
								'grid-cols-1 mt-6 grid gap-3 grid-flow-row-dense',
								isNew ? 'sm:grid-cols-2' : 'sm:grid-cols-3',
							].join(' ')}
						>
							<Button
								text="Close"
								onClick={onClose}
								fullWidth
								color={ButtonColors.plain}
								className="border border-gray-300 dark:border-darkGray-500"
							/>
							{!isNew && (
								<>
									<Button
										text="Delete"
										onClick={() =>
											setConfirmDeleteLocationModal(OpenClosedStates.Open)
										}
										fullWidth
										disabled={isSubmitting}
										color={ButtonColors.red}
									/>
									<ConfirmModal
										state={confirmDeleteLocationModal}
										close={() =>
											setConfirmDeleteLocationModal(OpenClosedStates.Closed)
										}
										confirm={() => onDelete()}
										title="Delete this location?"
										description="Deleting this location will also delete the products assigned to it. This operation is irreperable. If you have confirmed that the account holder wishes to proceed under these conditions, click yes below to delete the location."
									/>
								</>
							)}
							<Button
								text="Save"
								type="submit"
								fullWidth
								loading={isSubmitting}
								disabled={!isDirty || isSubmitting}
							/>
						</div>
						<AddContactEmailModal
							state={addContactEmailModal}
							patchValue={patchValue}
							currEmails={value.contactFormEmails}
							close={() => setAddContactEmailModal(OpenClosedStates.Closed)}
						/>
					</>
				</Modal>
			</form>
		</>
	);
};

export default AccountLocationModal;
