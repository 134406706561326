import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/outline';
import WarningAlert from '../../common/alerts/WarningAlert';
import Button, { ButtonColors } from '../../common/Button';
import { MeContext } from '../../common/contexts/me.context';
import Header from '../../common/Header';
import { OpenClosedStates } from '../../common/OpenClosedStates';
import AccountSettingsLocationModal from './AccountSettingsLocationModal';
import AccountSettingsTabs from './AccountSettingsTabs';
import Modal, { ModalSizes } from '../../common/Modal';

type Props = {
    locations: any[];
    saveLocation: (location: any) => void;
    removeLocation: (id: number) => void;
};

const AccountSettingsLocations: React.FC<Props> = ({
    locations,
    saveLocation,
    removeLocation,
}) => {
    const { me } = useContext(MeContext) || { me: {} };
    const [selectedLocation, setSelectedLocation] = useState<any>(null);
    const [locationModalState, setLocationModalState] = useState(OpenClosedStates.Closed);
    const [showInitialModal, setShowInitialModal] = useState(false);
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
    const corporatePlanActive = me?.account?.corporatePlan;
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (me?.account?.setupComplete === false && me?.account?.locations?.length === 0) {
            setShowInitialModal(true);
        }
    }, [me?.account?.setupComplete, me?.account?.locations?.length]);

    useEffect(() => {
        if (location.state?.openModal) {
            setLocationModalState(OpenClosedStates.Open);
            setSelectedLocation(location.state?.location || {});
        }
    }, [location.state]);

    const limitMet = useMemo(() => {
        let limitMetVal = false;
        let limit = 1;
        const activeLocations = locations?.filter((loc) => loc.active);
        if (me?.account?.newPlan) {
            limitMetVal =
                activeLocations?.length >=
                (me?.account?.subscription?.options?.additionalLocations || 0) + 1;
            limit =
                (me?.account?.subscription?.options?.additionalLocations || 0) + 1;
        } else {
            limitMetVal =
                activeLocations?.length >= me?.account?.subscription?.locations;
            limit = me?.account?.subscription?.locations;
        }
        return {
            limitMetVal,
            limit,
        };
    }, [
        locations,
        me?.account?.newPlan,
        me?.account?.subscription?.locations,
        me?.account?.subscription?.options?.additionalLocations,
    ]);

    useEffect(() => {
        if (me?.account?.setupComplete === false) {
            if (locations.length === 0) {
                setShowInitialModal(true);
            } else if (locations.length > 0) {
                if (limitMet.limit > locations.length) {
                    setShowSubscriptionModal(true);
                } else if (limitMet.limit <= locations.length) {
                    navigate('/users');
                }
            }
        }
    }, [me?.account?.setupComplete, limitMet, locations.length, navigate]);

    const openLocation = (location: any) => {
        setSelectedLocation(location);
        setLocationModalState(OpenClosedStates.Open);
    };

    const handleSkipToUsers = () => {
        setShowSubscriptionModal(false);
        navigate('/users');
    };

    return (
        <>
            <Header
                title="Locations"
                rightSide={
                    <Button
                        text="Add location"
                        onClick={() => openLocation({ path: '' })}
                        color={ButtonColors.primary}
                        disabled={limitMet.limitMetVal}
                    />
                }
            >
                <AccountSettingsTabs
                    manufacturer={me?.account?.type === 'Manufacturer'}
                    plan={me?.account?.subscription?.plan}
                />
            </Header>
            <div className="px-6 pt-6 bg-gray-50 h-full">
                {limitMet.limitMetVal && (
                    <WarningAlert>
                        Subscription limit met. You can only have {limitMet.limit} active
                        locations with your current subscription.{' '}
                        <Link
                            to="/billing"
                            className="font-medium underline text-yellow-700 hover:text-yellow-600"
                        >
                            Update your subscription to add more locations.
                        </Link>
                    </WarningAlert>
                )}
                <div className="bg-white shadow overflow-hidden sm:rounded-md mt-6">
                    {locations.length === 0 ? (
                        <div className="px-4 py-4 text-center text-gray-500">
                            <p className="text-sm">
                                You don't have any locations created yet. Click{" "}
                                <span className="font-medium text-blue-600">
                                    "Add Location"
                                </span>{" "}
                                above to get started.
                            </p>
                        </div>
                    ) : (
                    <ul className="divide-y divide-gray-200">
                        {locations?.map((location) => (
                            <li
                                key={location?.id}
                                onClick={() => openLocation(location)}
                                className={[
                                    'block hover:bg-gray-50 cursor-pointer',
                                    location?.active ? 'text-gray-500' : 'text-gray-300',
                                ].join(' ')}
                            >
                                <div className="px-4 py-4 flex items-center sm:px-6">
                                    <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                        <div className="truncate">
                                            <div className="flex text-sm">
                                                <p className="font-medium truncate">
                                                    {location?.address}
                                                </p>
                                            </div>
                                            <div className="mt-2 flex">
                                                <div className="flex items-center text-sm">
                                                    <p>
                                                        {location?.city}, {location?.state} {location?.zip}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ml-5 flex-shrink-0">
                                        <ChevronRightIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    )}
                </div>
            </div>
            {selectedLocation && (
                <AccountSettingsLocationModal
                    state={locationModalState}
                    setState={setLocationModalState}
                    location={selectedLocation}
                    saveLocation={saveLocation}
                    removeLocation={removeLocation}
                    disabled={
                        !selectedLocation?.active &&
                        locations?.filter((loc) => loc.active)?.length >= limitMet?.limit
                    }
                    dealerPathPart={me?.account?.path}
                    locations={me?.account?.locations}
                    hasSms={me?.account?.subscription?.options?.smsNotifications}
                    setSelectedLocation={setSelectedLocation}
                />
            )}
            {showSubscriptionModal && (
                <Modal state={OpenClosedStates.Open} size={ModalSizes.md}>
                    <div >
                        <h3 className="text-lg font-medium">Add Location(s)</h3>
                        <p className="mt-2">
                            {corporatePlanActive ? `Your subscription allows for unlimited locations. Would you like to add another?` : `Your subscription allows for {limitMet.limit} locations. Would you like to add another?`}
                        </p>
                        <div className="mt-4 flex justify-end space-x-4">
                        <Button text="Add another location"                         
                            onClick={() => {
                                setShowInitialModal(false); 
                                setShowSubscriptionModal(false);
                                openLocation({ path: '' }); 
                            }
                        }
                                
                        />
                            <Button text="Skip to Users" onClick={handleSkipToUsers} color={ButtonColors.plain} />
                        </div>
                    </div>
                </Modal>
            )}
            {showInitialModal && (
                <Modal state={OpenClosedStates.Open} size={ModalSizes.md}>
                    <div className="text-center">
                        <h3 className="text-lg font-medium">Welcome</h3>
                        <p className="mt-2">
                            It looks like you need to set up your locations. Please add a location to continue.
                        </p>
                        <div className="flex justify-center mt-4 space-x-4">
                        <Button
                            text="Add location"
                            onClick={() => {
                                setShowInitialModal(false); 
                                openLocation({ path: '' }); 
                            }}
                        />
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default AccountSettingsLocations;
