import React, { useState, useRef, useContext, ChangeEvent, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../helpers';
import { v4 } from 'uuid';
import { MeContext } from './contexts/me.context';
import uploadService from '../services/upload.service';
import Loading from './Loading';
import { PhotographIcon } from '@heroicons/react/outline';

type Props = {
  label: string;
  name: string;
  value?: string;
  onChange: (val: string) => void;
  className?: string;
};

type CropArea = {
  x: number;
  y: number;
  width: number;
  height: number;
};

const LogoUploader: React.FC<Props> = ({ label, name, value, onChange, className = '' }) => {
  const { me } = useContext(MeContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CropArea | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<string | null>(value || null);

  useEffect(() => {
    setUploadedImage(value ?? null);
  }, [value]);

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImageSrc(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = (croppedArea: CropArea, croppedAreaPixels: CropArea) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const uploadCroppedImage = async () => {
    if (!croppedAreaPixels || !imageSrc) return;
    setIsLoading(true);
    try {
      const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      if (!(croppedImageBlob instanceof Blob)) throw new Error('Invalid cropped image format');
      const file = new File([croppedImageBlob], `${name}-${v4()}.jpeg`, { type: 'image/jpeg' });
      const url = await uploadService.upload(me?.account.id, file, 'image/jpeg');
      const updatedUrl = `${url}?t=${new Date().getTime()}`; // Force refresh
      onChange(updatedUrl);
      setUploadedImage(updatedUrl);
      setImageSrc(null);
    } catch (error) {
      console.error('Error processing image:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={className}>
      <label className="block text-sm font-medium text-gray-900">{label}</label>
      <div className="mt-2 items-center gap-4">
        <div className="bg-white border-4 rounded-full h-56 w-56 flex items-center justify-center -mt-10">
          {uploadedImage ? (
            <img src={uploadedImage} alt={label} className="h-full w-full object-cover rounded-full" />
        ) : (
            <PhotographIcon className="h-full w-full text-gray-400" />
        )}
        </div>
        <button 
          onClick={() => inputRef.current?.click()} 
          className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 mr-4">
          Upload
        </button>

        {/* {imageSrc?.startsWith("data:image") && (
          <button 
            onClick={() => setImageSrc(uploadedImage)} 
            className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 mr-4">
            Edit
          </button>
        )} */}

        {uploadedImage && (
          <button 
            onClick={() => { setUploadedImage(null); onChange(''); }} 
            className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            Remove
          </button>
        )}

        <input ref={inputRef} type="file" hidden onChange={onFileChange} />
      </div>
      {imageSrc && (
        <div className="fixed inset-0 p-6 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative  flex flex-col items-center">
            <h2 className="text-lg font-semibold mb-4">Adjust Your Logo</h2>
            <div className="relative w-full h-72">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                minZoom={0.5}
                maxZoom={3}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                restrictPosition={false}
                cropShape="round" 
              />
            </div>
            <input
              type="range"
              min="0.5"
              max="3"
              step="0.1"
              value={zoom}
              onChange={(e) => setZoom(Number(e.target.value))}
              className="mt-4 w-3/4"
            />
            <div className="flex gap-4 mt-4">
              <button onClick={uploadCroppedImage} className="bg-primary-500 text-white p-2 rounded-md">
                Ok
              </button>
              <button onClick={() => setCrop({ x: 0, y: 0 })} className="text-gray-600 p-2 rounded-md border border-gray-300">
                Recenter
              </button>
              <button onClick={() => setImageSrc(null)} className="text-gray-600 p-2 rounded-md border border-gray-300">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isLoading && <Loading />}
    </div>
  );
};

export default LogoUploader;
